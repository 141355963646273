<template>
  <div class="beforeTranslate">
    <ai-navigation :displayName="displayName" :email="email"></ai-navigation>

    <!-- Topscreen -->
    <main id="topscreen" class="topscreen">
      <div id="contents">
        <lang-container :class="{ openSettingPanel: !isSettingsHided }" />

        <setting-panel
          v-model="modal"
          @toggle-settings="isSettingsHided = $event"
          :data="csvData"
          :langData="lang"
        ></setting-panel>
        <!-- /END #settingPanel -->
      </div>
      <!-- /END #contents -->

      <div class="topscreen_overlay"></div>
    </main>
    <!-- /END #topscreen -->

    <zoom-modal
      v-if="modal === 'zoom'"
      :data="csvData"
      @resetModal="modal = $event"
    />
    <teams-modal
      v-if="modal === 'teams'"
      :data="csvData"
      @resetModal="modal = $event"
    />
    <meet-modal
      v-if="modal === 'meet'"
      :data="csvData"
      @resetModal="modal = $event"
    />
    <whereby-modal
      v-if="modal === 'whereby'"
      :data="csvData"
      @resetModal="modal = $event"
    />
    <hangouts-modal v-if="modal === 'hangouts'" @resetModal="modal = $event" />
    <skype-modal
      v-if="modal === 'skype'"
      :data="csvData"
      @resetModal="modal = $event"
    />
    <extension-install
      id="install"
      v-if="showInstall"
      :data="csvData"
      @resetModal="modal = $event"
    />
    <loading id="loading" v-if="isLoading" @resetModal="modal = $event" />
  </div>
</template>

<script>
import LangContainer from "@/components/lang-container.vue";
import AiNavigation from "@/components/ai-navigation.vue";
import SettingPanel from "@/components/setting-panel.vue";
import ZoomModal from "@/components/modals/zoom-modal.vue";
import TeamsModal from "@/components/modals/teams-modal.vue";
import HangoutsModal from "@/components/modals/hangouts-modal.vue";
import ExtensionInstall from "@/components/modals/extension-install.vue";
import WherebyModal from "@/components/modals/whereby-modal.vue";
import MeetModal from "@/components/modals/meet-modal.vue";
import SkypeModal from "@/components/modals/skype-modal.vue";
import Loading from "@/components/modals/loading.vue";
import { getCurrentUser } from "@/firebase.js";
import { signin } from "@/altid.js";
import { IDP_URL } from "@/env/publicConfig.json";
import * as Papa from "papaparse";
import * as base64 from "base-64";

const patterns = [
  "af-ZA",
  "am-ET",
  "ar-AE",
  "ar-BH",
  "ar-DZ",
  "ar-EG",
  "ar-IQ",
  "ar-JO",
  "ar-KW",
  "ar-LB",
  "ar-LY",
  "ar-MA",
  "arn-CL",
  "ar-OM",
  "ar-QA",
  "ar-SA",
  "ar-SY",
  "ar-TN",
  "ar-YE",
  "as-IN",
  "az-Cyrl-AZ",
  "az-Latn-AZ",
  "ba-RU",
  "be-BY",
  "bg-BG",
  "bn-BD",
  "bn-IN",
  "bo-CN",
  "br-FR",
  "bs-Cyrl-BA",
  "bs-Latn-BA",
  "ca-ES",
  "co-FR",
  "cs-CZ",
  "cy-GB",
  "da-DK",
  "de-AT",
  "de-CH",
  "de-DE",
  "de-LI",
  "de-LU",
  "dsb-DE",
  "dv-MV",
  "el-GR",
  "en-029",
  "en-AU",
  "en-BZ",
  "en-CA",
  "en-GB",
  "en-IE",
  "en-IN",
  "en-JM",
  "en-MY",
  "en-NZ",
  "en-PH",
  "en-SG",
  "en-TT",
  "en-US",
  "en-ZA",
  "en-ZW",
  "es-AR",
  "es-BO",
  "es-CL",
  "es-CO",
  "es-CR",
  "es-DO",
  "es-EC",
  "es-ES",
  "es-GT",
  "es-HN",
  "es-MX",
  "es-NI",
  "es-PA",
  "es-PE",
  "es-PR",
  "es-PY",
  "es-SV",
  "es-US",
  "es-UY",
  "es-VE",
  "et-EE",
  "eu-ES",
  "fa-IR",
  "fi-FI",
  "fil-PH",
  "fo-FO",
  "fr-BE",
  "fr-CA",
  "fr-CH",
  "fr-FR",
  "fr-LU",
  "fr-MC",
  "fy-NL",
  "ga-IE",
  "gd-GB",
  "gl-ES",
  "gsw-FR",
  "gu-IN",
  "ha-Latn-NG",
  "he-IL",
  "hi-IN",
  "hr-BA",
  "hr-HR",
  "hsb-DE",
  "hu-HU",
  "hy-AM",
  "id-ID",
  "ig-NG",
  "ii-CN",
  "is-IS",
  "it-CH",
  "it-IT",
  "iu-Cans-CA",
  "iu-Latn-CA",
  "ja-JP",
  "ka-GE",
  "kk-KZ",
  "kl-GL",
  "km-KH",
  "kn-IN",
  "kok-IN",
  "ko-KR",
  "ky-KG",
  "lb-LU",
  "lo-LA",
  "lt-LT",
  "lv-LV",
  "mi-NZ",
  "mk-MK",
  "ml-IN",
  "mn-MN",
  "mn-Mong-CN",
  "moh-CA",
  "mr-IN",
  "ms-BN",
  "ms-MY",
  "mt-MT",
  "nb-NO",
  "ne-NP",
  "nl-BE",
  "nl-NL",
  "nn-NO",
  "nso-ZA",
  "oc-FR",
  "or-IN",
  "pa-IN",
  "pl-PL",
  "prs-AF",
  "ps-AF",
  "pt-BR",
  "pt-PT",
  "qut-GT",
  "quz-BO",
  "quz-EC",
  "quz-PE",
  "rm-CH",
  "ro-RO",
  "ru-RU",
  "rw-RW",
  "sah-RU",
  "sa-IN",
  "se-FI",
  "se-NO",
  "se-SE",
  "si-LK",
  "sk-SK",
  "sl-SI",
  "sma-NO",
  "sma-SE",
  "smj-NO",
  "smj-SE",
  "smn-FI",
  "sms-FI",
  "sq-AL",
  "sr-Cyrl-BA",
  "sr-Cyrl-CS",
  "sr-Cyrl-ME",
  "sr-Cyrl-RS",
  "sr-Latn-BA",
  "sr-Latn-CS",
  "sr-Latn-ME",
  "sr-Latn-RS",
  "sv-FI",
  "sv-SE",
  "sw-KE",
  "syr-SY",
  "ta-IN",
  "te-IN",
  "tg-Cyrl-TJ",
  "th-TH",
  "tk-TM",
  "tn-ZA",
  "tr-TR",
  "tt-RU",
  "tzm-Latn-DZ",
  "ug-CN",
  "uk-UA",
  "ur-PK",
  "uz-Cyrl-UZ",
  "uz-Latn-UZ",
  "vi-VN",
  "wo-SN",
  "xh-ZA",
  "yo-NG",
  "zh-CN",
  "zh-HK",
  "zh-MO",
  "zh-SG",
  "zh-TW",
  "zu-ZA",
];
export default {
  components: {
    LangContainer,
    AiNavigation,
    SettingPanel,
    ZoomModal,
    TeamsModal,
    HangoutsModal,
    WherebyModal,
    MeetModal,
    SkypeModal,
    ExtensionInstall,
    Loading,
  },
  data: () => ({
    isLoading: false,
    modal: "",
    isSettingsHided: false,
    showInstall: false,
    needLogin: false,
    displayName: "",
    email: "",
    lang: "en",
    csvData: {
      cc: "en",
      extension1: "Please install the extension.",
      extension2:
        'It seems that the extension is not installed <br>in this browser. This service uses the "AI <br>HONYAKU extension" for Google Chrome. <br>Please click the button below to install the <br>extension. <br><br>This screen will not appear after the <br>extension installation is complete.',
      extension3: "Install the extension",
      privacy1: "I agree to",
      privacy2: "the terms of use",
      privacy3: "of AI HONYAKU.",
      meetingurl: "Meeting room URL",
      zoom1:
        "Enter the URL and display name of the Zoom meeting room to start Zoom.",
      zoom2: "Display Name",
      zoom3: "AI Interpreter",
      zoom4: "Start Zoom translating",
      teams1:
        'Enter the Teams meeting as usual and click "Join on the web / Continue on your browser".',
      teams2: "Start Teams interpreting",
      gmeet1:
        "Enter Google Meet and start interpreting. Please enter the URL of the meeting.",
      gmeet2: "Start Google Meet translating",
      whereby1:
        "Enter Whereby and start interpreting. Please enter the URL of the meeting.",
      whereby2: "Start Whereby interpreting",
      skype1:
        "Enter Skype and start interpreting. Please enter the URL of the meeting.",
      skype2: "Start Skype interpreting",
      menu1: "Zoom",
      menu2: "Teams",
      menu3: "Google Meet",
      menu4: "Whereby",
      menu5: "Skype",
      menu6: "Speech",
      menu7: "Destination",
    },
  }),
  watch: {
    modal() {},
    isSettingsHided() {},
  },
  methods: {
    openInstall() {
      const key = require("./extensionid.json");
      const extensionid = key.extensionid;
      console.log("extensionid ===", extensionid);
      this.detectExtension(extensionid); //'bklhnippjlkciileljfpiepjdjclhnno');
    },
    detectExtension(extensionId) {
      this.intervalLoading = setInterval(() => {
        this.isLoading = false;
      }, 500);

      var img;
      img = new Image();
      img.src = "chrome-extension://" + extensionId + "/icon16.png";
      img.onload = function () {
        localStorage.setItem("stateInstall", false);
      };
      img.onerror = function () {
        localStorage.setItem("stateInstall", true);
      };
    },
    showModal() {
      var is = localStorage.getItem("stateInstall");
      if (is === "true") {
        this.showInstall = localStorage.getItem("stateInstall");
      }
    },
    checkInstall(data) {
      this.csvData = data;
      localStorage.setItem("stateInstall", null);
      this.openInstall();
      this.interval = setInterval(() => this.showModal(), 1000);
    },
    createInput(file, aLang, callBack) {
      Papa.parse(file, {
        download: true,
        // rest of config ...
        header: true,
        encoding: "Unicode",
        dynamicTyping: false,
        skipEmptyLines: true,
        complete: function (results) {
          results.data.forEach((element) => {
            if (element.cc === aLang) {
              this.csvData = element;
              callBack(this.csvData);
              return;
            }
          });
        },
      });
    },
    getCsvData() {
      var csvFile = "https://app.linguacode.ai/languages.csv";
      //var csvFile = "http://localhost:8080/languages.csv";
      this.createInput(csvFile, this.lang, this.checkInstall);

      if (this.needLogin) {
        if (this.lang == "ja") {
          window.location.href = "https://honyaku.ai"; //`${IDP_URL}/login?product_code=honyakuaaa`;
        } else {
          window.location.href = `${IDP_URL}/login?product_code=linguacode`;
          //console.log(IDP_URL);
        }
      }
    },
  },
  async mounted() {
    const { token } = this.$route.query;
    if (token) {
      await signin(token);
      window.location.href = "/";
    }
    const user = await getCurrentUser();
    if (!user || !user.uid) {
      this.needLogin = true;
    }
    // this.displayName = user.displayName;
    // this.email = user.email;

    this.isLoading = true;
    var callTime = localStorage.getItem("callTime");
    var setLang = localStorage.getItem("setLang");
    let curTime = Math.round(new Date() / 1000);

    if (
      (callTime == null) |
      (setLang == null) |
      (curTime - callTime > 604800) |
      (callTime == "null") |
      (setLang == "null")
    ) {
      fetch("https://api.ipify.org?format=json")
        .then((x) => x.json())
        .then(async ({ ip }) => {
          console.log("IP Address ===", ip);
          console.log(
            "Basic auth ===",
            base64.encode("597469:BQFke1WywkIMpecC")
          );

          let headers = new Headers();
          // headers.append(
          //   "Authorization",
          //   "Basic " + base64.encode("597469:BQFke1WywkIMpecC")
          // );
          headers.append("Content-Type", "application/json");
          fetch(
            "https://us-central1-ai-honyaku-global.cloudfunctions.net/api/geoip/country/" +
              ip,
            // "https://linguacode-app.herokuapp.com/https://geolite.info/geoip/v2.1/country/" +
            //   ip +
            //   "?pretty",
            {
              method: "GET",
              headers: headers,
            }
          )
            .then(async (response) => {
              const data = await response.json();
              console.log("response ===", data.country.iso_code);
              let language_code = null;
              patterns.map((item) => {
                const splits = item.split("-");
                if (
                  splits.length > 1 &&
                  splits[splits.length - 1] == data.country.iso_code &&
                  language_code == null
                ) {
                  language_code = splits[0];
                }
              });

              this.lang = language_code;
              localStorage.setItem("callTime", this.curTime);
              localStorage.setItem("setLang", this.lang);
              console.log("this.lang ===", this.lang);
              this.getCsvData();
            })
            .catch(function (error) {
              console.log("error == " + error);
              this.getCsvData();
            });
        })
        .catch(function (error) {
          console.log("error == " + error);
          this.getCsvData();
        });
    } else {
      this.lang = setLang;
      this.getCsvData();
    }
  },
};
</script>

<style>
.modal-overlay {
  background: rgba(0, 0, 0, 0.3);
}
@font-face {
  font-family: "ElvishFont";
  src: local("JunctionRegular"),
   url(./assets/fonts/tnganbi.woff) format("truetype");
}
</style>
