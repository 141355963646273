<template>
  <section id="langContainer" class="langContainer">
    <div id="langDefault" class="langDefault">
      <textarea
        id="speechText"
        :style="{ fontSize: speechTextSize + 'px' }"
        disabled
        v-model="speekText"
      >
先日のお見積もりの件ですが、ご要望に沿う条件でご提案することが可能のようです。こちらの先日のお見積もりの件ですが、ご要望に沿う条件でご提案することが可能のようです。こちらの先日のお見積もりの件ですが、ご要望に沿う条件でご提案することが可能のようです。こちらの先日のお見積もりの件ですが、ご要望に沿う条件でご提案することが可能のようです。こちらの資料をご覧ください。先日のお見積もりの件ですが、ご要望に沿う条件でご提案することが可能のようです。こちらの資料をご覧ください。</textarea
      >
    </div>
    <div id="langTranslate" class="langTranslate">
      <textarea
        id="translateText"
        :style="{ fontSize: translateTextSize + 'px', fontFamily: specialLang == 'ev' ? 'ElvishFont' : ''}"
        disabled
        v-model="translateText"
      >
Regarding your recent quotation, it seems that it is possible for us to offer you a proposal with terms that meet your requirements. Please take a look at this document.</textarea
      >
    </div>
    
    <textarea id="outLanguage" v-show="false" v-model="langText" style="position:absolute">
      Elvish
    </textarea>
  </section>
</template>
<script>
var SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
var recognition = SpeechRecognition ? new SpeechRecognition() : false;

import { EventBus } from "../Event.js";
import { getCurrentUserIdToken } from "../firebase";

export default {
  props: {
    lang: {
      type: String,
      default: "ja-JP",
    },
  },
  data() {
    return {
      error: false,
      speaking: false,
      toggle: false,
      isChange: false,
      speekText: "",
      translateText: "",
      runtimeTranscription: "",
      sentences: [],
      inputLang: "ja",
      outputLang: "en",
      specialLang: "",
      speechTextSize: 30,
      translateTextSize: 30,
      tts: "google",
      langText: ""
    };
  },
  methods: {
    updateSize() {
      var speechDivEl = document.getElementById("speechText");
      if (speechDivEl.scrollHeight > speechDivEl.clientHeight) {
        this.speechTextSize = this.speechTextSize * 0.9;
      } else {
        this.speechTextSize = 30;
      }

      var translateDivEl = document.getElementById("translateText");
      if (translateDivEl.scrollHeight > translateDivEl.clientHeight) {
        this.translateTextSize = this.translateTextSize * 0.9;
      } else {
        this.translateTextSize = 30;
      }

      setTimeout(() => {
        this.updateSize();
      }, 10);
    },
    calc() {
      setTimeout(() => {
        this.updateSize();
      }, 0);
    },
    checkCompatibility() {
      if (!recognition) {
        this.error =
          "Speech Recognition is not available on this browser. Please use Chrome or Firefox";
        return;
      }
      this.startSpeechRecognition();
    },
    endSpeechRecognition() {
      this.translateGoogle("I speak English");
    },
    startSpeechRecognition() {
      if (!recognition) {
        this.error =
          "Speech Recognition is not available on this browser. Please use Chrome or Firefox";
        return false;
      }
      this.toggle = true;
      recognition.lang = this.inputLang;
      recognition.interimResults = true;

      recognition.addEventListener("speechstart", (event) => {
        this.speaking = true;
        console.log("speechstart " + event + " = " + this.inputLang);
      });

      recognition.addEventListener("speechend", (event) => {
        this.speaking = false;
        console.log("speechend " + event);
      });

      recognition.addEventListener("result", (event) => {
        const text = Array.from(event.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join("");
        this.runtimeTranscription = text;
        this.speekText = text;
      });

      recognition.addEventListener("end", () => {
        if (this.runtimeTranscription !== "") {
          this.sentences.push(
            this.capitalizeFirstLetter(this.runtimeTranscription)
          );
          console.log(
            "addEventListener end " +
              `${this.text}${this.sentences.slice(-1)[0]}. `
          );

          this.$emit(
            "update:text",
            `${this.text}${this.sentences.slice(-1)[0]}. `
          );
        }
        this.runtimeTranscription = "";
        if (this.toggle) {
          // keep it going.
          try {
            recognition.start();
          } catch (e) {
            console.log("Exception: ", e);
          }
        }
      });
      if (!this.isChange) recognition.start();
      this.isChange = false;
    },
    capitalizeFirstLetter(string) {
      console.log("Final " + string.charAt(0).toUpperCase() + string.slice(1));
      this.speekText = string.charAt(0).toUpperCase() + string.slice(1);
      this.translateGoogle(this.speekText);
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    async translateGoogle(text) {
      console.log("translateSpecial ==text=== " + text + " == " + this.inputLang + " == " + this.specialLang);
      if (this.inputLang == 'en' && this.specialLang == 'ev') {
        this.translateSpecial(text);
        return;
      }
      const token = await getCurrentUserIdToken();
      //console.log(token);
      fetch(
        "https://us-central1-ai-honyaku-global.cloudfunctions.net/api/translate?inputLang=" +
          this.inputLang +
          "&outputLang=" +
          this.outputLang +
          "&text=" +
          text,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          method: "GET",
        }
      )
        .then(async (response) => {
          const data = await response.json();
          if (!data.result) return "";
          this.translateTextSize = 30;
          if (this.specialLang === "ev"){
            this.translateSpecial(data.result);
          }
          else{
            this.translateText = data.result;
          }
        })
        .catch(function (error) {
          console.log("error == " + error);
        });
    },
    translateSpecial(text) {
      console.log("translateSpecial ==text=== " + text);
      let result = "";
      fetch("https://us-central1-ai-honyaku-global.cloudfunctions.net/api/sindarin?text=" + text)
        .then(async (response) => {
          const data = await response.json();
          if (!data.result) return "";
          result = data.result;
          console.log("translateSpecial ===== " + result);
          this.translateText = result;
        })
        .catch(function (error) {
          console.log("error == " + error);
        });
    }
  },
  mounted() {
    this.calc();
    this.checkCompatibility();
    EventBus.$on("changeInputLang", (language) => {
      this.inputLang = language;
      this.isChange = true;
      this.toggle = true;
      this.startSpeechRecognition();
    });
    EventBus.$on("changeOutputLang", (language, language2) => {
      this.outputLang = language;
      this.specialLang = language2;
      this.langText = language2;
    });
  }
};
</script>

<style>
.langContainer {
  height: calc(100vh - 90px);
  margin-top: 70px;
  width: calc(100% - 55px);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.langContainer.openSettingPanel {
  width: calc(100% - 250px);
}
.langDefault,
.langTranslate {
  height: calc(50vh - 45px);
  margin: 0 20px 0 40px;
  padding: 40px 0 !important;
  width: calc(100% - 60px);
}
.langTranslate {
  border-top: 1px solid rgba(255, 255, 255, 0.3) !important;
}
.langDefault textarea,
.langTranslate textarea {
  background: transparent;
  border: 0 !important;
  color: #fff;
  height: 100%;
  padding: 0;
  resize: none;
}
.langDefault textarea:focus {
  color: #fff;
}
.langTranslate textarea,
.langTranslate textarea:focus {
  color: #fff300;
}

@media (max-width: 767px) {
  .langContainer.openSettingPanel {
    width: calc(100% - 55px);
  }
}

@media (max-width: 575px) {
  .langContainer.openSettingPanel {
    width: calc(100% - 55px);
  }
}
</style>
