<template>
  <div
    class="modal fade exportServicesModal show modal-overlay"
    style="display: block"
    tabindex="-1"
  >
    <the-modal :modal-id="'exportZoomModal'">
      <div class="modal-body">
        <p style="font-size: 26px; font-weight: bold">
          <span v-html="data.extension1"></span>
          <br />
        </p>
        <p style="font-size: 17px">
          <span v-html="data.extension2"></span>
        </p>
      </div>

      <template #footer>
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          data-toggle="modal"
          data-target="#recording"
          @click="gotoInstall"
        >
          {{ data.extension3 }}
        </button>
      </template>
    </the-modal>
  </div>
</template>

<script>
import TheModal from "./the-modal.vue";

export default {
  components: {
    TheModal,
  },
  props: {
    data: {},
  },
  data: () => ({
    roomId: "",
    password: "",
    isChecked: false,
  }),
  methods: {
    gotoInstall() {
      //window.location.href = "https://chrome.google.com/webstore/detail/bulk-url-opener-extension/hgenngnjgfkdggambccohomebieocekm";
      //let routeData = this.$router.resolve({name: 'routeName', query: {data: "someData"}});
      window.open(
        "https://chrome.google.com/webstore/detail/linguacodeai/fggofdajciompjmmolalakfhncihpglf/related",
        "_blank"
      );
    },
  },
};
</script>

