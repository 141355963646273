import firebase from 'firebase/app'
import 'firebase/auth'
import FIREBASE_CONFIG from './env/firebaseConfig.json'

if (!firebase.apps.length) {
  firebase.initializeApp(FIREBASE_CONFIG)
}

export const auth = firebase.auth()

export const getCurrentUser = () => {
  return new Promise((resolve) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe()
      if (user) resolve(user)
      else resolve(null)
    })
  })
}

export const getCurrentUserUid = async () => {
  const user = await getCurrentUser()
  return user ? user.uid : ''
}

export const getCurrentUserIdToken = async (authRequired = false) => {
  const user = await getCurrentUser()
  if (authRequired && !user) {
    throw 'Firebase datastore, User auth required'
  }
  return user ? await user.getIdToken(true) : ''
}